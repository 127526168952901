import React from 'react';
import PamperImage from '../assets/pamp.webp'

const WellnessPackage = () => {
  return (
    <div className="container mx-auto px-4 py-10 font-philosopher">
      {/* First Row */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Column (Title and List) */}
        <div className="lg:p-8 md:p-8  sm:p-4 min-[320px]:p-4 bg-Purlwhite rounded-md">
          <h2 className="lg:text-3xl md:text-3xl sm:text-xl min-[320px]:text-xl font-semibold text-Brownish mb-4">Wellness Package</h2>
          <ul className="list-disc pl-12 space-y-2">
            <li className="text-lg text-gray-600">Traditional Head Massage</li>
            <li className="text-lg text-gray-600">Traditional Foot & Leg Massage</li>
            <li className="text-lg text-gray-600">Traditional Full Body Massage</li>
            <li className="text-lg text-gray-600">Traditional Full Body Massage</li>
            <li className="text-lg text-gray-600">Four Hand Full Body Massage</li>
            <li className="text-lg text-gray-600">De-stress Thermo Massage</li>
            <li className="text-lg text-gray-600">Shirodhara Therapy (Relaxing head treatment)</li>
            <li className="text-lg text-gray-600">Padadhara Therapy (Relaxing leg treatment)</li>
            <li className="text-lg text-gray-600">Sarvangadhara Therapy (Rejuvenating body treatment)</li>
            <li className="text-lg text-gray-600">Foot Reflex Pressure Massage</li>
            <li className="text-lg text-gray-600">Steam Body Sauna</li>
            <li className="text-lg text-gray-600">Nasal Steam Inhalation</li>
            <li className="text-lg text-gray-600">Nasal Therapy</li>
            <li className="text-lg text-gray-600">Aromatic Milk Bath</li>
            <li className="text-lg text-gray-600">Exotic Herbal Bath</li>
            <li className="text-lg text-gray-600">Moderate Colon Cleansing</li>

          </ul>
        </div>

        {/* Right Column (Image) */}
        <div className="lg:w-full h-auto">
          <img
            src={PamperImage}
            alt="Wellness Spa"
            className="lg:w-[700px] lg:h-[700px] rounded-lg shadow-lg"
          />
        </div>
      </div>

      {/* Second Row (Two Boxes) */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-10">
        {/* Box 1 */}
        <div className="bg-Brownish/50 p-6 rounded-lg shadow-lg text-center border-4 border-solid border-neutral-700">
          <h3 className="text-2xl font-semibold text-Black mb-4">06 Days</h3>
          <p className="text-xl text-DarkGreen">
          Euro 452 / USD $575.00
          </p>
          <p className="text-xl text-DarkGreen">
          (18 + 03 Bonus Treatments)
          </p>
        </div>

        {/* Box 2 */}
        <div className="bg-Brownish/50 p-6 rounded-lg shadow-lg text-center border-4 border-solid border-neutral-700">
        <h3 className="text-2xl font-semibold text-Black mb-4">12 Days</h3>
          <p className="text-xl text-DarkGreen">
          Euro 752 / USD $957.00
          </p>
          <p className="text-xl text-DarkGreen">
          (36 + 03 Bonus Treatments)
          </p>
        </div>
      </div>
    </div>
  );
};

export default WellnessPackage;
