import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaGoogle, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import emailjs from 'emailjs-com'; 

import ContactImage from '../assets/contact.webp'

const ContactInfo = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await emailjs.send(
        'service_xlm5xtl',  
        'template_t8t7it8', 
        formData,           
        'Yi374alIFnUoZ70MP'      
      );

      if (response.status === 200) {
        alert('Your message has been sent!');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        alert('Something went wrong, please try again.');
      }
    } catch (error) {
      alert('Error sending message');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 bg-gray-100 font-philosopher">
      {/* Get in Touch Content Section */}
      <div className="flex flex-col justify-center bg-DarkGreen lg:p-16 md:p-10 sm:p-8 min-[320px]:p-8">
        <h2 className="text-4xl font-bold text-white ">Get in Touch</h2>
        <p className="mt-4 text-lg text-white/50">
          We would love to hear from you. Please reach out with any questions or feedback.
        </p>

        <div className="mt-8 space-y-4 flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col justify-between">
          <div className='flex flex-col gap-y-3'>
            {/* Location Icon */}
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-Brownish mr-4 text-xl" />
              <span className="text-sm text-white">475/A/2 Negombo Road, Dandugama Thotupola, Ja-ela, Sri Lanka.</span>
            </div>

            {/* Phone Icon */}
            <div className="flex items-center">
              <FaPhoneAlt className="text-Brownish mr-4 text-xl" />
              <span className="text-sm text-white">+94 778 481 494</span>
            </div>

            {/* Email Icon */}
            <div className="flex items-center">
              <FaEnvelope className="text-Brownish mr-4 text-xl" />
              <span className="text-sm text-white">riversidecolombo@gmail.com</span>
            </div>
          </div>

          <div className="flex lg:mt-8 md:mt-8 sm:mt-10 min-[320px]:mt-10 space-x-6 ">
            {/* Social Media Icons */}
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="text-Brownish text-xl" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="text-Brownish text-xl" />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="text-Brownish text-xl" />
            </a>
            <a href="https://plus.google.com" target="_blank" rel="noopener noreferrer">
              <FaGoogle className="text-Brownish text-xl" />
            </a>
          </div>
        </div>

        {/* Email Form Section */}
        <div className="mt-10">
          <h3 className="text-2xl font-semibold text-white">Contact Form</h3>
          <form onSubmit={handleSubmit} className="mt-6 space-y-4">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md text-lg focus:outline-none bg-transparent text-white"
              placeholder="Your Name"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md text-lg focus:outline-none  bg-transparent text-white"
              placeholder="Your Email"
              required
            />
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md text-lg focus:outline-none  bg-transparent text-white"
              placeholder="Subject"
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-md text-lg focus:outline-none  bg-transparent text-white"
              placeholder="Your Message"
              rows="4"
              required
            />
            <button
              type="submit"
              className={`w-full py-2 bg-Brownish text-white rounded-md text-lg ${isSubmitting ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Submit'}
            </button>
          </form>
        </div>
      </div>

      {/* Image Section */}
      <div className="hidden lg:block">
        <img
          src={ContactImage}
          alt="Contact"
          className="w-full h-full object-cover shadow-md"
        />
      </div>
    </div>
  );
};

export default ContactInfo;
