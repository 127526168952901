import singleBedroom from '../src/assets/Rooms/single.jpg'
import doubleBedroom from '../src/assets/Rooms/double.jpg'
import FamilyBedroom from '../src/assets/Rooms/Family.jpg'

import HappyPackage from '../src/assets/offers/happy.webp'
import SundayFundayPackage from '../src/assets/offers/sunday.webp'
import FamilyFunPackage from '../src/assets/offers/family.webp'
import LakeFunPackage from '../src/assets/offers/lake.webp'
import HookPackage from '../src/assets/offers/hooking.webp'
import RomanticPackage from '../src/assets/offers/romantic.webp'
import PamperPackage from '../src/assets/offers/pamper.webp'
import ThrillingPackage from '../src/assets/offers/spa.webp'

import DPackOne from '../src/assets/DayOuts/package1.webp'
import DPackTwo from '../src/assets/DayOuts/package2.webp'
import DPackThree from '../src/assets/DayOuts/package3.webp'
import DPackFour from '../src/assets/DayOuts/package4.webp'
import DPackFive from '../src/assets/DayOuts/package5.webp'

const roomsData = {
  acRooms: [
    {
      id: 1,
      imageUrl: singleBedroom,
      type: 'Single Room',
      persons: 1,
      beds: 1,
      wifi: true,
      threeHours: 2500,
      twelveHours:4500,
      twentyFourHours:6500
    },
    {
      id: 2,
      imageUrl: doubleBedroom,
      type: 'Double Room',
      persons: 2,
      beds: 1,
      wifi: true,
      threeHours: 3500,
      twelveHours:6500,
      twentyFourHours:8500
    },
    {
      id: 3,
      imageUrl: FamilyBedroom,
      type: 'Family Room',
      persons: 4,
      beds: 2,
      wifi: true,
      threeHours: 4000,
      twelveHours:7000,
      twentyFourHours: 10000
    },
  ],
  nonAcRooms: [
    {
      id: 1,
      imageUrl: singleBedroom,
      type: 'Single Room',
      persons: 1,
      beds: 1,
      wifi: true,
      threeHours: 1500,
      twelveHours:3500,
      twentyFourHours:5000
    },
    {
      id: 2,
      imageUrl: doubleBedroom,
      type: 'Double Room',
      persons: 2,
      beds: 2,
      wifi: true,
      threeHours: 2500,
      twelveHours:4500,
      twentyFourHours:6500
    },
    {
      id: 3,
      imageUrl: FamilyBedroom,
      type: 'Family Room',
      persons: 4,
      beds: 2,
      wifi: true,
      threeHours: 3000,
      twelveHours:5000,
      twentyFourHours:8500
    },
  ],
};

export const packageData = [
  {
    id: 1,
    title: "Happy Package",
    price: "$200 PLUS TAX AND RESORT FEE",
    image:HappyPackage,
    description: "Experience happiness with our exclusive happy package.",
    list: [
      "One Night stay in a Luxury Room",
      "Breakfast for 2",
      "A Dozen Chocolate Covered Strawberries",
      "Bottle of Champagne",
    ]
  },
  {
    id: 2,
    title: "Sunday Funday",
    price: "STARTING AT ONLY $110",
    image: SundayFundayPackage,
    description: "Relax and enjoy your Sunday with this amazing package.",
    list: [
      "2 Massages for $100 on Sunday ONLY",
      "For each paying adult 1 child under 12 years of age eats for FREE",
    ]
  },
  {
    id: 3,
    title: "Family Fun Package",
    price: "WEEKDAY - $450 , WEEKEND - $575",
    image: FamilyFunPackage,
    description: "Perfect for families to spend quality time together.",
    list: [
      " Two Nights Lodging in a Luxury Room",
      "Breakfast each morning (Up to 4 people)",
      "Half day boat rental (Upgrade to ski boat for $100 additional Monday-Thursday only.) Guest is responsible for fuel.",
    ]
  },
  {
    id: 4,
    title: "Lake Fun",
    price: "$285",
    image:LakeFunPackage,
    description: "Enjoy serene lakeside experiences with this package.",
    list: [
      "One Night Lodging in a Luxury Room",
      "Your choice of a Half Day Pontoon Boat Rental or a 2 hour Jet Ski Rental (Guest responsible for gas.)",
      "Monday – Thursday ONLY!",
      "*Upgrades are available for an additional fee. (Subject to availability)",
    ]
  },
  {
    id: 5,
    title: "Hook Package",
    price: "$750",
    image: HookPackage,
    description: "Fishing enthusiasts will love this Hook package.",
    list: [
      " One Night Lodging in a Luxury Room",
      "Exclusive Private Fishing Charter for 4",
      "$125 for each additional fisherman.",
      "Upgrades are available for additional fee. (Subject to availability)"
    ]
  },
  {
    id: 6,
    title: "Romantic Package",
    price: "$340",
    image:RomanticPackage,
    description: "Create memories with your loved one with this romantic package.",
    list: [
      "One Night Lodging in a Luxury Room",
      "Breakfast for 2",
      "Bottle of Wine or Champagne",
      "Two 60 Minute Massages",
      "Upgrades are available for an additional fee. (Subject to availability)"
    ]
  },
  {
    id: 7,
    title: "Put in Pamper Package",
    price: "WEEKDAY - $220 , WEEKEND - $270",
    image: PamperPackage,
    description: "Pamper yourself with a relaxing and luxurious experience.",
    list: [
      " One Night Lodging in a Luxury Room",
      "Breakfast for 2",
      "One Round of Golf",
      "One Spa Treatment: Choose 1 of the following: 60 minute Massage, Facial, Hands and Feet Treatment",
     
    ]
  },
  {
    id: 8,
    title: "Thrilling Package",
    price: "$300",
    image:ThrillingPackage ,
    description: "Feel the adrenaline rush with this thrilling adventure package.",
    list: [
      " One Night Lodging in a Luxury Room",
      "Bottle of Wine or Champagne",
      "Choice of 2 of the following services:60 minute Massage, Reflexology 60 minute Treatment, Detox Facial"
    ]
  }
];

export const dayOutpackages = [
  {
    id: 1,
    name: 'Day Out Package 1',
    price: 8000,
    peopleCount: 2,
    poolAccessCount: 2,
    acRoom: false,
    otherFacilities: ['Welcome Drink', 'Lunch'],
    image: DPackOne, 
  },
  {
    id: 2,
    name: 'Day Out Package 2',
    price: 10000,
    peopleCount: 2,
    poolAccessCount: 2,
    acRoom: true,
    otherFacilities: ['Welcome Drink', 'Lunch'],
    image:DPackTwo,
  },
  {
    id: 3,
    name: 'Day Out Package 3',
    price: 15000,
    peopleCount: 2,
    poolAccessCount: 2,
    acRoom: true,
    otherFacilities: ['Welcome Drink', 'Lunch', 'Snack With Tea', 'Dinner' , 'Morning Tea' , 'Breakfast'],
    image: DPackThree,
  },
  {
    id: 4,
    name: 'Day & Night 24 Hours',
    price: 18000,
    peopleCount: 2,
    poolAccessCount: 2,
    acRoom: true,
    otherFacilities: ['Welcome Drink', 'Lunch', 'Snack With Tea', 'Dinner' , 'Morning Tea' , 'Breakfast','Again Lunch or Dinner'],
    image:DPackFour,
  },
  {
    id: 5,
    name: 'Day & Night 24 Hours',
    price: 22000,
    peopleCount: 3,
    poolAccessCount: 3,
    acRoom: true,
    otherFacilities: ['Welcome Drink', 'Lunch', 'Snack With Tea', 'Dinner' , 'Morning Tea' , 'Breakfast'],
    image: DPackFive,
  },
  
];



export default roomsData;

