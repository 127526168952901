// src/components/WhyChooseUs.js
import React from 'react';
import { FaWifi, FaHotel, FaCreditCard, FaConciergeBell } from 'react-icons/fa';

const WhyChooseUs = () => {
  return (
    <div className="py-16 bg-Purlwhite">
      <div className="max-w-screen-xl mx-auto text-center px-4">
        <h2 className="text-3xl font-bold text-Brownish mb-8">Why Choose Us?</h2>
        <p className="text-lg text-gray-600 mb-16">
          Here are just a few reasons why we stand out from the competition. Experience luxury and comfort like never before.
        </p>

        {/* Reasons Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* First Reason */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaWifi className="text-Brownish text-4xl mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-gray-800">Free Wi-Fi</h3>
            <p className="text-gray-600 mt-2">
              Stay connected with our fast and reliable Wi-Fi, available throughout the hotel.
            </p>
          </div>

          {/* Second Reason */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaHotel className="text-Brownish text-4xl mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-gray-800">Luxurious Rooms</h3>
            <p className="text-gray-600 mt-2">
              Our rooms are designed for your comfort and relaxation, with premium amenities and elegant decor.
            </p>
          </div>

          {/* Third Reason */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaCreditCard className="text-Brownish text-4xl mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-gray-800">Easy Payments</h3>
            <p className="text-gray-600 mt-2">
              We offer multiple payment options for your convenience, including credit card and mobile payment.
            </p>
          </div>

          {/* Fourth Reason */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <FaConciergeBell className="text-Brownish text-4xl mb-4 mx-auto" />
            <h3 className="text-xl font-semibold text-gray-800">24/7 Concierge Service</h3>
            <p className="text-gray-600 mt-2">
              Our dedicated concierge team is always available to help you with anything you need, anytime.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;
