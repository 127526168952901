import React from 'react';
import BoatOne from '../assets/Activites/BoatFour.webp'
import BoatTwo from '../assets/Activites/BoatTwo.webp'
import BoatThree from '../assets/Activites/BoatThree.webp'

const BoatRidePackages = () => {
  return (
    <section className="text-center py-8 px-4 font-philosopher">
      <h2 className="text-2xl font-semibold mb-8 text-Brownish">Boat Ride Packages</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        
        {/* First Column */}
        <div className="flex flex-col items-center space-y-4">
          <div className="w-full h-52 overflow-hidden relative bg-gray-300 rounded-md">
            <img src={BoatTwo} alt="Boat Ride 1" className="w-full h-full object-cover" />
          </div>
          <div className="relative p-4 w-full text-center h-52 bg-DarkGreen flex flex-col justify-center rounded-md">
            {/* Horizontal Line at the top */}
            <div className="absolute top-0 left-0 right-0 h-px bg-Brownish/50 pt-2 rounded-md"></div>
      
            {/* Vertical Line on the left */}
            <div className="absolute top-0 left-0 bottom-0 w-px bg-Brownish/50 pl-2 rounded-md"></div>
      
            <h3 className="text-2xl font-semibold mb-2 text-Brownish">River Boat Safari</h3>
            <p className="text-white/80">US$100 - 4 hour</p>
            <p className="text-white/80">$30 For Additional Each hour</p>
          </div>
        </div>
      
        {/* Second Column (Reversed on Mobile) */}
        <div className="flex lg:flex-col md:flex-col sm:flex-col-reverse min-[320px]:flex-col-reverse items-center lg:space-y-4 md:space-y-4 sm:gap-y-4 min-[320px]:gap-y-4 lg:gap-y-0 md:gap-y-0">
          <div className="relative p-4 w-full text-center h-52 bg-DarkGreen flex flex-col justify-center rounded-md">
            {/* Horizontal Line at the top */}
            <div className="absolute top-0 left-0 right-0 h-px bg-Brownish/50 pt-2 rounded-md"></div>
      
            {/* Vertical Line on the left */}
            <div className="absolute top-0 left-0 bottom-0 w-px bg-Brownish/50 pl-2 rounded-md"></div>
      
            <h3 className="text-2xl font-semibold mb-2 text-Brownish">Lagoon Boat Safari</h3>
            <p className="text-white">US$100 - 4 hour</p>
            <p className="text-white">$30 For Additional Each hour</p>
          </div>
          <div className="w-full h-52 overflow-hidden relative bg-gray-300 rounded-md">
            <img src={BoatOne} alt="Boat Ride 1" className="w-full h-full object-cover" />
          </div>
        </div>
      
        {/* Third Column */}
        <div className="flex flex-col items-center space-y-4">
          <div className="w-full h-52 overflow-hidden relative bg-gray-300 rounded-md">
            <img src={BoatThree} alt="Boat Ride 1" className="w-full h-full object-cover" />
          </div>
          <div className="relative p-4 w-full text-center h-52 bg-DarkGreen flex flex-col justify-center rounded-md">
            {/* Horizontal Line at the top */}
            <div className="absolute top-0 left-0 right-0 h-px bg-Brownish/50 pt-2 rounded-md"></div>
      
            {/* Vertical Line on the left */}
            <div className="absolute top-0 left-0 bottom-0 w-px bg-Brownish/50 pl-2 rounded-md"></div>
      
            <h3 className="text-2xl font-semibold mb-2 text-Brownish">Islands Visit Boat Safari</h3>
            <p className="text-white">US$100 - 4 hour</p>
            <p className="text-white">$30 For Additional Each hour</p>
          </div>
        </div>
      
      </div>
      
    </section>
  );
}

export default BoatRidePackages;
