import React from 'react';
import { FaUsers, FaSwimmer, FaBed } from 'react-icons/fa';

const Card = ({ packageData, openModal }) => {
  const { name, price, peopleCount, poolAccessCount, acRoom, otherFacilities, image } = packageData;

  return (
    <div className="relative max-w-sm rounded overflow-hidden shadow-lg font-philosopher bg-Purlwhite lg:w-[450px]">
      <img className="w-full h-48 object-cover" src={image} alt={name} />
      {/* <button 
        onClick={() => openModal(packageData)} 
        className="absolute top-36 right-2 bg-black text-white py-2 px-4 rounded"
      >
        Book Now
      </button> */}
      <a className="absolute top-36 right-2 bg-black text-white py-2 px-4 rounded" href='https://docs.google.com/forms/d/e/1FAIpQLSeAuMYTrDnGok9XRXNrcAWEtOtd0PdRZHlcekaCa3hTkALuWg/viewform?usp=dialog' target='_blank'>Book Now</a>
      <div className="px-6 py-4">
        <h2 className="font-bold text-xl mb-2">{name}</h2>
        <p className="text-gray-700 text-base">
          <span className="text-lg font-semibold text-Brownish">LKR  {price}</span>
        </p>
        <div className="flex items-center space-x-4 mt-4">
          <div className="flex items-center space-x-1">
            <FaUsers />
            <span>{peopleCount} People</span>
          </div>
          <div className="flex items-center space-x-1">
            <FaSwimmer />
            <span>{poolAccessCount > 0 ? `${poolAccessCount} Pools` : 'No Pool'}</span>
          </div>
          <div className="flex items-center space-x-1">
            <FaBed />
            <span>{acRoom ? 'AC Room' : 'Non-AC'}</span>
          </div>
        </div>
        <div className="mt-4">
          <h3 className="font-semibold">Other Facilities:</h3>
          <ul className="list-disc pl-5">
            {otherFacilities.map((facility, index) => (
              <li key={index}>{facility}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Card;
