// Reducer to handle the active link state
const activeLinkReducer = (state = '', action) => {
    switch (action.type) {
      case 'SET_ACTIVE_LINK':
        return action.payload;
      default:
        return state;
    }
  };
  
  export default activeLinkReducer;
  