import React, { useEffect } from 'react';
import { CountUp } from 'countup.js';

const CountingBar = () => {
  // Example data
  const yearsOfExperience = 10;
  const customersSatisfied = 500;
  const numberOfRooms = 50;

  useEffect(() => {
    // Initialize CountUp animations when the component is mounted
    const countUp1 = new CountUp('countto1', yearsOfExperience);
    const countUp2 = new CountUp('countto2', customersSatisfied);
    const countUp3 = new CountUp('countto3', numberOfRooms);

    if (!countUp1.error) countUp1.start();
    if (!countUp2.error) countUp2.start();
    if (!countUp3.error) countUp3.start();
  }, [yearsOfExperience, customersSatisfied, numberOfRooms]);

  return (
    <div className="container flex flex-col items-center gap-16 mx-auto my-10">
      <div className="grid w-full grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-y-8">
        {/* Column 1: Years of Experience */}
        <div className="flex flex-col items-center">
          <h3 className="text-5xl font-extrabold leading-tight text-center text-dark-grey-900">
            <span id="countto1"></span>
          </h3>
          <p className="text-base font-medium leading-7 text-center text-dark-grey-600">
            Years of Experience
          </p>
        </div>

        {/* Column 2: Customers Satisfied */}
        <div className="flex flex-col items-center">
          <h3 className="text-5xl font-extrabold leading-tight text-center text-dark-grey-900">
            <span id="countto2"></span>
          </h3>
          <p className="text-base font-medium leading-7 text-center text-dark-grey-600">
            Customers Satisfied
          </p>
        </div>

        {/* Column 3: Number of Rooms */}
        <div className="flex flex-col items-center">
          <h3 className="text-5xl font-extrabold leading-tight text-center text-dark-grey-900">
            <span id="countto3"></span>
          </h3>
          <p className="text-base font-medium leading-7 text-center text-dark-grey-600">
            Number of Rooms
          </p>
        </div>
      </div>
    </div>
  );
};

export default CountingBar;
