// src/components/Packages.js
import React, { useState } from 'react';
import Sidebar from '../components/SideBar';
import Content from '../components/PackagesContent';
import { packageData } from '../db';

const Packages = () => {
  const [activeTag, setActiveTag] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleTagClick = (id) => {
    setActiveTag(id);
    const selected = packageData.find((pkg) => pkg.id === id);
    setSelectedPackage(selected);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown on mobile/tablet
  };

  return (
    <div className="flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col  w-full font-philosopher">
      {/* Sidebar */}
      
      <div className='w-1/4 lg:block md:block sm:hidden min-[320px]:hidden'>
      <Sidebar
        tags={packageData}
        activeTag={activeTag}
        onTagClick={handleTagClick}
        
      />
      </div>
      
     {/* Dropdown for mobile/tablet */}
      <div className="lg:hidden md:hidden sm:block">
        <button
          onClick={toggleDropdown}
          className="w-full p-4 bg-DarkGreen text-white text-lg"
        >
          {activeTag ? packageData.find(pkg => pkg.id === activeTag).title : 'Select a Package'}
        </button>
        {isDropdownOpen && (
          <div className="w-full bg-Purlwhite">
            {packageData.map((tag) => (
              <button
                key={tag.id}
                className={`w-full p-4 text-left ${activeTag === tag.id ? 'text-Brownish bg-gray-200' : ''}`}
                onClick={() => handleTagClick(tag.id)}
              >
                {tag.title}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Content */}
      <div className='flex  lg:w-3/4 md:w-3/4 sm:w-full min-[320px]:w-full'>
      <Content selectedPackage={selectedPackage} />
      </div>
      
    </div>
  );
};

export default Packages;
