import React from 'react';
import { FaWifi, FaBed } from 'react-icons/fa';

const RoomCard = ({ room }) => {
  return (
    <div className="flex flex-col w-full md:w-1/3 bg-white shadow-lg rounded-md overflow-hidden font-philosopher">
      <img src={room.imageUrl} alt={room.type} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-xl font-semibold">{room.type}</h3>
        <div className="flex flex-row gap-x-6">
          <p className="text-gray-600 mt-2">Persons: {room.persons}</p>
          <div className="flex items-center mt-2">
            <FaBed className="text-gray-500" />
            <span className="ml-1">{room.beds} Beds</span>
          </div>
          <div className="flex items-center mt-2">
            {room.wifi ? <FaWifi className="text-green-500" /> : <FaWifi className="text-gray-500" />}
            <span className="ml-1">{room.wifi ? 'Wi-Fi Included' : 'No Wi-Fi'}</span>
          </div>
        </div>

        <div className="mt-4 flex-row flex justify-between gap-x-2">
          <div className="bg-DarkGreen text-white px-2 py-2 text-center rounded-md text-[14px]">
            LKR {room.threeHours} - 3 Hours
          </div>
          <div className="bg-DarkGreen text-white px-2 py-2 text-center rounded-md text-[14px]">
            LKR {room.twelveHours} - 12 Hours
          </div>
          <div className="bg-Brownish text-black px-2 py-2 text-center rounded-md text-[14px]">
            LKR {room.twentyFourHours} - 24 Hours
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
