import React from "react";
import CampOne from '../assets/Camp/camp1.webp'
import CampTwo from '../assets/Camp/camp2.webp'
import CampThree from '../assets/Camp/camp3.webp'


const Camping = () => {
  return (
    <div className=" mx-auto px-6 py-12 font-philosopher bg-black/10">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {/* Gallery Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* First Column - Vertical Image */}
          <div
            className="w-full h-[400px] lg:h-[620px] bg-cover bg-center rounded-xl "
            style={{ backgroundImage: `url(${CampOne})` }}
          />
          {/* Second Column - Two Horizontal Images */}
          <div className="space-y-6">
            <div
              className="w-full h-[200px] lg:h-[300px] bg-cover bg-center rounded-xl "
              style={{ backgroundImage: `url(${CampThree})` }}
            />
            <div
              className="w-full h-[200px] lg:h-[300px] bg-cover bg-center rounded-xl"
              style={{ backgroundImage:`url(${CampTwo})` }}
            />
          </div>
        </div>

        {/* Content Section */}
        <div className="">
          {/* Heading and Description */}
          <div>
            <h2 className="text-4xl font-bold text-Brownish tracking-tight">Camping Adventure</h2>
            <div className="py-6">
            <p className="text-lg text-black mt-4 leading-relaxed">
            Our River Side hotel offers a peaceful escape surrounded by nature’s beauty. With the river gently flowing nearby and a picturesque lake just a short walk away, guests can enjoy stunning views, fresh air, and the calming sounds of nature.
            </p>
            <p className="text-lg text-black mt-4 leading-relaxed">
            Explore the area’s rich wildlife, including various bird species that grace the lake and surrounding trees. The cool environment provides a sense of freedom, allowing guests to relax, enjoy leisurely walks, or engage in peaceful moments, creating an unforgettable experience.            </p>
            </div>
           
          </div>

          {/* Price Card */}
          <div className="bg-Brownish/40 p-6 rounded-xl shadow-xl transform transition-all duration-300 ease-in-out hover:scale-105">
            <div className="flex justify-between items-center text-black">
              <span className="text-2xl font-semibold text-black/70">Adventure Package</span>
              {/* <span className="text-2xl font-semibold">$199</span> */}
            </div>
            <div className="mt-4 text-black">
              <div className="flex justify-between">
                <span className="text-lg">Duration</span>
                <span className="text-lg">3 Days</span>
              </div>
              <div className="flex justify-between mt-2">
                <span className="text-lg">Per Person</span>
                <span className="text-lg">LKR 2000</span>
              </div>
              <div className="flex justify-between mt-2">
                <span className="text-lg">Available Time</span>
                <span className="text-lg">EveryDay</span>
              </div>
            </div>
            <div className="pt-4">
                <p className="font-bold text-xl text-black/50">To Reserve Call : 077 8481 494</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Camping;

