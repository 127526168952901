// src/components/PackagesContent.js
import React from 'react';
import PackageSideImage from '../assets/offers/packageone.webp'
import PackageSideImageTwo from '../assets/offers/packagetwo.webp'

const Content = ({ selectedPackage }) => {
  return (
    <div className="flex-1 font-philosopher">
      {selectedPackage ? (
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 m-4">
          <div className="w-full md:w-1/2 ">
            <img
              src={selectedPackage.image}
              alt={selectedPackage.title}
              className="w-full h-[600px] object-cover rounded-l-lg"
            />
          </div>
          <div className="w-full md:w-1/2 lg:p-8 md:p-8 sm:p-5 min-[320px]:p-5 bg-Purlwhite h-[600px] rounded-r-lg">
          <div className='bg-white w-full  h-[527px] p-8'>
          <h2 className="text-3xl font-bold mb-4">{selectedPackage.title}</h2>
            <h2 className="text-xl font-bold mb-4 text-Brownish">{selectedPackage.price}</h2>
            <p className="text-lg">{selectedPackage.description}</p>
            <ul className="list-disc pl-5 py-6 text-[#595754]">
              {selectedPackage.list && selectedPackage.list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
           
          </div>
        </div>
      ) : (
        <div className="bg-[#d9d6d0] lg:h-[650px]">
          <div className="gap-16 items-center justify-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
            <div className="font-light text-DarkGreen sm:text-lg ">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-Brownish ">Escape to Riverside</h2>
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 "> A Tranquil Lakeside Getaway</h2>
              <p className="mb-6 text-[15px]">Discover Riverside Hotel, a serene lakeside retreat that combines natural beauty with ultimate comfort. Surrounded by lush landscapes, our hotel offers a peaceful escape with cozy accommodations and affordable package prices. Whether you seek relaxation or adventure, Riverside is the perfect destination.</p>
              <p className='text-[15px]'>At Riverside Hotel, immerse yourself in the tranquility of nature while enjoying modern comforts. Nestled by a picturesque lake, our reasonable packages ensure a peaceful stay in a beautiful environment. Let the calm waters and scenic views offer the perfect backdrop for your getaway.</p>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-8">
              <img className="w-full rounded-lg" src={PackageSideImage} alt="office content 1"></img>
              <img className="mt-4 w-full lg:mt-10 rounded-lg" src={PackageSideImageTwo} alt="office content 2"></img>
            </div>
          </div>
        </div>

      )}
    </div>
  );
};

export default Content;
