import React from "react";
import CampOne from '../assets/BBQ/bbgthree.webp'
import CampTwo from '../assets/BBQ/bbqtwo.webp'
import CampThree from '../assets/BBQ/bbqone.webp'


const BBQPackage = () => {
  return (
    <div className=" mx-auto px-6 py-12 font-philosopher bg-black/10">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {/* Gallery Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* First Column - Vertical Image */}
          <div
            className="w-full h-[400px] lg:h-[620px] bg-cover bg-center rounded-xl "
            style={{ backgroundImage: `url(${CampOne})` }}
          />
          {/* Second Column - Two Horizontal Images */}
          <div className="space-y-6">
            <div
              className="w-full h-[200px] lg:h-[300px] bg-cover bg-center rounded-xl "
              style={{ backgroundImage: `url(${CampThree})` }}
            />
            <div
              className="w-full h-[200px] lg:h-[300px] bg-cover bg-center rounded-xl"
              style={{ backgroundImage:`url(${CampTwo})` }}
            />
          </div>
        </div>

        {/* Content Section */}
        <div className="">
          {/* Heading and Description */}
          <div>
            <h2 className="text-4xl font-bold text-Brownish tracking-tight">BBQ Packages</h2>
            <div className="py-6">
            <p className="text-lg text-black mt-4 leading-relaxed">
            The Riverside Hotel offers a picturesque and serene environment for your BBQ evening, with a spacious outdoor area located near the pool.The tranquil surroundings, along with ample space, make it an ideal location for both intimate gatherings and larger parties, ensuring everyone has a memorable experience.            </p>
            <p className="text-lg text-black mt-4 leading-relaxed">
            Our BBQ package provides you with everything needed for a fun-filled evening. We offer all the necessary BBQ equipment, so you can focus on enjoying yourself. A variety of food options are available, including different meats that you can either bring along or purchase from us. We also provide a wide selection of beverages to complement your BBQ experience, ensuring all your refreshment needs are met. Additionally, bonfires are included in the package, adding a cozy and festive atmosphere to your gathering.</p>            </div>
           
          </div>

          {/* Price Card */}
          <div className="bg-Brownish/40 p-6 rounded-xl shadow-xl transform transition-all duration-300 ease-in-out hover:scale-105">
            <div className="flex justify-between items-center text-black">
              <span className="text-2xl font-semibold text-black/70">BBQ Package</span>
              {/* <span className="text-2xl font-semibold">$199</span> */}
            </div>
            <div className="mt-4 text-black">
              <div className="flex justify-between">
                <span className="text-lg">Duration</span>
                <span className="text-lg">5.30 PM - 12.30 PM</span>
              </div>
              <div className="flex justify-between mt-2">
                <span className="text-lg">Per Person</span>
                <span className="text-lg">LKR 2000</span>
              </div>
            
            </div>
            <div className="pt-4">
                <p className="font-bold text-xl text-black/50">To Reserve Call : 077 8481 494</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BBQPackage;

