import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import roomsData from '../db';
import RoomCard from '../components/RoomDetails';
import BookingModal from '../payments/BookingModel';

const AllRooms = () => {
  const location = useLocation();
  const roomType = location.state?.roomType;
  const rooms = roomType === 'ac' ? roomsData.acRooms : roomsData.nonAcRooms;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);

  // Open the modal with a specific room
  const openModal = (room) => {
    setSelectedRoom(room);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="p-4 font-philosopher">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl text-center mb-8 font-bold text-Brownish">
          {roomType === 'ac' ? 'AC Rooms' : 'Non AC Rooms'}
        </h2>

        {/* Book Now button to open the modal without selecting a room */}
        {/* <button
          onClick={() => openModal(null)} // You can remove this if you want to open for a specific room only
          className="bg-DarkGreen text-white px-4 py-2 rounded-md"
        >
          Book Now
        </button> */}
        <a  className="bg-DarkGreen text-white px-4 py-2 rounded-md" href='https://docs.google.com/forms/d/e/1FAIpQLSdPNN78fHGRgHNqQMAbJ22w0IVuE8QY_0-jUEaMI0q6vuZamg/viewform?usp=dialog' target='_blank'>Book Now</a>
      </div>

      <div className="flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col justify-between gap-x-2">
        {rooms.map((room) => (
          <RoomCard key={room.id} room={room} onClick={() => openModal(room)} />
        ))}
      </div>

      {isModalOpen && (
        <BookingModal
          room={selectedRoom}
          roomType={roomType} // Passing roomType to the modal
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default AllRooms;
