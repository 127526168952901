import React from "react";
import VisaImage from '../assets/Payements/visacard.png'
import MasterCardImage from '../assets/Payements/mastercard.png'
import CashImage from '../assets/Payements/cash.png'
import BankImage from '../assets/bank.jpg' // Assuming you have a bank image

const PaymentOptions = () => {
  return (
    <div className="container mx-auto px-4 py-8 font-philosopher bg-black/5">
      <div className="text-center">
        <h2 className="text-3xl font-semibold mb-4">Payment Options</h2>
        <p className="text-lg text-gray-600 mb-8">
          We accept: VISA, MASTERCARD & CASH. In addition, we accept Cheques.
        </p>
      </div>

      {/* Payment Methods */}
      <div className="flex flex-row justify-center lg:gap-8 md:gap-8 sm:gap-4 min-[320px]:gap-4 mb-8">
        <div className="flex flex-col items-center">
          <img src={VisaImage} alt="Visa" className="h-12 mb-2" />
          <p className="text-sm text-gray-700">VISA</p>
        </div>
        <div className="flex flex-col items-center">
          <img src={MasterCardImage} alt="Mastercard" className="h-12 mb-2" />
          <p className="text-sm text-gray-700">MASTERCARD</p>
        </div>
        <div className="flex flex-col items-center">
          <img src={CashImage} alt="Cash" className="h-12 mb-2" />
          <p className="text-sm text-gray-700">CASH</p>
        </div>
      </div>

      {/* Other Information */}
      <div className="flex lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col gap-x-4">
        {/* Cheques Information */}
        <div className="bg-Brownish/50 p-6 rounded-lg shadow-md mb-8 lg:w-1/3">
          <h3 className="text-xl font-semibold mb-2">Cheques</h3>
          <p className="text-sm text-black">
            Requires prior authorization with Management and must be cleared in our bank account prior to the arrival. No personal cheques will be accepted at check-in and/or during the entire stay. Normally we require 5 to 7 business days for the process of receiving, clearing in our account and entering the cheque amount as credit onto your reservation.
          </p>
        </div>

        {/* Cancellation Policy */}
        <div className="bg-red-200 p-6 rounded-lg shadow-md mb-8 lg:w-1/3">
          <h3 className="text-xl font-semibold mb-2 text-red-600">Non-Refundable Cancellation Policy</h3>
          <p className="text-sm text-black">
            This reservation requires full pre-payment and is non-cancellable, non-amendable, and non-refundable. We will charge the full amount of the reservation to the credit card you used to make this reservation shortly after the reservation is made.
          </p>
        </div>

        {/* Amenities */}
        <div className="bg-Brownish/40 p-6 rounded-lg shadow-md mb-8 lg:w-1/3">
          <h3 className="text-xl font-semibold mb-2">Amenities</h3>
          <ul className="list-disc pl-5 text-sm text-black">
            <li>Complimentary Wireless Internet</li>
            <li>Off-Site parking is available</li>
          </ul>
        </div>
      </div>

      {/* Bank Details and Reservations */}
      <div className="flex flex-col lg:flex-row gap-8">

        {/* Bank Details Side */}
        <div className="lg:w-1/2  p-6 rounded-lg shadow-md bg-black/5">
          <h3 className="text-xl font-semibold mb-4 text-Brownish">Bank Details</h3>

          <div className="flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col gap-4 mb-6">
            {/* Bank Image (vertical image) */}
            <div className="lg:w-1/2 md:w-1/2 sm:w-full min-[320px]:w-full">
              <img src={BankImage} alt="Bank" className="h-full object-cover" />
            </div>

            {/* Payment Banking Details */}
            <div className="lg:w-1/2 md:w-1/2 sm:w-full min-[320px]:w-full gap-y-2 flex flex-col">
              <p className="text-sm">To make a direct payment, please use the following bank details. We recommend making your payment through bank transfer for a seamless and secure transaction. Kindly provide these details during your payment process to ensure timely confirmation of your booking. If you have any questions or need assistance, feel free to contact us</p>
              <p className="lg:text-md md:text-md sm:text-sm min-[320px]:text-sm text-gray-700">
                Bank Name: HNB BANK<br />
                Branch Name: KOLLUPITIYA BRANCH<br />
                Account Number: 115010153452<br/>
                Account Name : PULS LANKA (pvt) LTD
              </p>
            </div>
          </div>
        </div>

        {/* Reservations Side */}
        <div className="lg:w-1/2  p-6 rounded-lg shadow-md bg-black/5">
          <h3 className="text-xl font-semibold mb-4 text-Brownish">Reserved Reservations</h3>
          {/* <p className="text-sm text-gray-700 mb-4">
            Below are your reserved reservations details. If you need to make any changes, please contact us.
          </p> */}

          <div className="space-y-4">

            <div className="text-sm text-gray-700 px-4">
              <p className="font-semibold">Reserve your room easily by calling us at the numbers below or by emailing us directly. Our team is ready to assist you with your booking and ensure a smooth reservation process.If you need to make any changes, please contact us.</p> 
            </div>

            <div className="text-sm text-gray-700 px-4">
              <p className="text-[16px]">Call Us : (+94) 778 481 494</p>
              <p className="text-[16px]">WhatsApp : (+61) 406 254 868</p>

              <p className="text-[16px]">Email : riversidecolombo@gmail.com</p>
            </div>

          </div>
          
        </div>
      </div>


    </div>
  );
};

export default PaymentOptions;
