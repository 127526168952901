import React from "react";
import { Route, Routes } from "react-router-dom";  // Use Routes and Route for navigation
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/About";
import RoomContent from "./pages/Rooms";
import AllRooms from "./pages/AllRooms";
import Packages from "./pages/Packages";
import ContactInfo from "./pages/Contact";
import PaymentOptions from "./pages/Payements";
import BoatRideComponent from "./pages/BoatRide";
import WellnessPackage from "./pages/WellnessPackage";
import DayOutPackages from "./pages/DayOutPackages";
import Campign from "./pages/Campign";
import BBQPackage from "./pages/BBQPackage";
function App() {
  return (
    <div className="App">
      {/* Header: It will be common across all pages */}
      <Header />
      
      {/* Routing: The page content changes here based on the active route */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/rooms" element={<RoomContent/>}></Route>
        <Route path="/all-rooms" element={<AllRooms/>}></Route>
        <Route path="/camping-details" element={<Campign/>}></Route>
        <Route path="/BBQ-events" element={<BBQPackage/>}></Route>
        <Route path="/boat-ride-details" element={<BoatRideComponent/>}></Route>
        <Route path="/wellness-package" element={<WellnessPackage/>}></Route>
        <Route path="/packages" element={<Packages/>}></Route>
        <Route path="/dayout-packages" element={<DayOutPackages/>}></Route>
        <Route path="/payments" element={<PaymentOptions/>}></Route>
        <Route path="/contacts" element={<ContactInfo/>}></Route>

      </Routes>
      
      {/* Footer: It will also be common across all pages */}
      <Footer />
    </div>
  );
}

export default App;
