// src/components/Sidebar.js
import React from 'react';

const Sidebar = ({ tags, activeTag, onTagClick }) => {
  return (
    <div className="p-4 space-y-4 font-philosopher items-center justify-center flex flex-col bg-DarkGreen lg:h-[650px] md:h-full">
      {tags.map((tag) => (
        <button
          key={tag.id}
          className={`text-lg p-2 rounded-sm w-[200px] text-left relative ${
            activeTag === tag.id
              ? 'text-Brownish border-b-2 border-Brownish'
              : 'hover:bg-transparent text-white'
          }`}
          onClick={() => onTagClick(tag.id)}
        >
          {tag.title}
          {activeTag === tag.id && (
            <div className="absolute bottom-0 left-0 w-full h-1 bg-DarkGreen"></div>
          )}
        </button>
      ))}
    </div>
  );
};

export default Sidebar;
