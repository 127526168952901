// LocalPaymentForm.js (Frontend - Local Payment like iPay or PayHere)
import React, { useState } from 'react';

const LocalPaymentForm = ({ amount, onClose }) => {
  const [paymentMethod, setPaymentMethod] = useState('ipay');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('/pay-local', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount, paymentMethod }),
    });

    const data = await response.json();

    if (data.success) {
      alert(`${data.message}`);
      onClose();
    } else {
      alert('Payment failed!');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        <input
          type="radio"
          value="ipay"
          checked={paymentMethod === 'ipay'}
          onChange={() => setPaymentMethod('ipay')}
        />
        iPay
      </label>
      <label>
        <input
          type="radio"
          value="payhere"
          checked={paymentMethod === 'payhere'}
          onChange={() => setPaymentMethod('payhere')}
        />
        PayHere
      </label>

      <button type="submit">Pay LKR {amount}</button>
    </form>
  );
};

export default LocalPaymentForm;
