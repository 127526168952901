import React, { useState } from 'react';
import { dayOutpackages } from '../db';
import Card from '../components/DayOutCard';
import PaymentModal from '../components/PayementModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to replace this with your Stripe public key
const stripePromise = loadStripe('your-publishable-key-here');

const App = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (packageData) => {
    setSelectedPackage(packageData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPackage(null);
  };

  return (
    <div className="flex flex-col items-center justify-center py-12 bg-black/5 px-4">
      <p className="text-Brownish font-semibold text-2xl font-philosopher py-5">
        Day Out Packages
      </p>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-12 ">
        {dayOutpackages.map((packageData) => (
          <Card
            key={packageData.id}
            packageData={packageData}
            openModal={openModal}
          />
        ))}
      </div>

      {/* Wrap the Payment Modal with Elements provider */}
      <Elements stripe={stripePromise}>
        <PaymentModal
          isOpen={isModalOpen}
          onClose={closeModal}
          packageData={selectedPackage}
        />
      </Elements>
    </div>
  );
};

export default App;
