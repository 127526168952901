import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaMapMarkerAlt, FaBars, FaTimes } from 'react-icons/fa';
import LogoImage from '../assets/Logo.png';
import { setActiveLink } from '../store/action';

const Header = () => {
  const dispatch = useDispatch();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const dropdownRef = useRef(null);
  const sidebarRef = useRef(null);

  const activeLink = useSelector((state) => state.activeLink);

  const handleLinkClick = (link) => {
    dispatch(setActiveLink(link));
    setSidebarVisible(false);
    setDropdownVisible(false);
  };

  useEffect(() => {
    if (!activeLink) {
      dispatch(setActiveLink('home'));
    }
  }, [dispatch, activeLink]);

  useEffect(() => {
    // Disable scrolling when the sidebar is open
    if (isSidebarVisible) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }

    // Cleanup: Reset the overflow style on component unmount or when sidebar is closed
    return () => {
      document.body.style.overflow = 'auto'; // Ensure scrolling is enabled when the component unmounts
    };
  }, [isSidebarVisible]); // Run whenever isSidebarVisible changes

  useEffect(() => {
    // Close the sidebar when clicking outside of it
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarVisible(false); // Close sidebar if clicked outside
      }

      // Close dropdown when clicking outside of dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false); // Close dropdown if clicked outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleActivitiesClick = (event) => {
    event.preventDefault();
    setDropdownVisible(!isDropdownVisible);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  return (
    <div className="font-philosopher">
      {/* Desktop Header (visible on larger screens) */}
      <div className="backdrop-blur-sm bg-black/90 px-4 flex justify-between items-center border-b-[0.02px] border-white relative z-10 py-4 hidden md:flex">
        {/* Location */}
        <div className="flex items-center space-x-2">
          <FaMapMarkerAlt className="text-Brownish" />
          <span className="text-xs font-semibold text-white">Seeduwa ja ela, Sri Lanka</span>
        </div>

        {/* Logo */}
        <div className="text-lg font-bold text-center text-white">
          <div className="flex flex-row gap-x-2">
            <img src={LogoImage} className="w-16 h-8" alt="Logo" />
            <span className="text-xl text-Brownish">RiverSide</span>Hotel
          </div>
        </div>

        {/* Contact Info */}
        <div className="flex items-center space-x-2">
          <FaPhoneAlt className="text-Brownish" />
          <span className="text-sm text-white">+94 778 481 494</span>
        </div>
      </div>

      {/* Mobile Header (visible on smaller screens) */}
      <div className="backdrop-blur-sm bg-black/90 px-4 py-4 flex justify-between items-center md:hidden">
        {/* Logo on Left */}
        <div className="flex items-center text-white">
          <img src={LogoImage} className="w-16 h-8" alt="Logo" />
          <span className="text-xl text-Brownish">RiverSide</span>Hotel
        </div>

        {/* Menu Icon on Right */}
        <div className="text-white text-xl cursor-pointer" onClick={() => setSidebarVisible(!isSidebarVisible)}>
          {isSidebarVisible ? <FaTimes /> : <FaBars />}
        </div>
      </div>

      {/* Sidebar Navigation for Mobile */}
      <div
        ref={sidebarRef}
        className={`fixed  left-0 w-3/4 h-full bg-black/90 top-16 text-white z-20 transform ${isSidebarVisible ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300`}
      >
        {/* Sidebar Content */}
        <div className="flex flex-col items-center space-y-4 gap-y-5 py-6">
          <Link
            to="/"
            className={`text-lg ${activeLink === 'home' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('home')}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`text-lg ${activeLink === 'about' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('about')}
          >
            About
          </Link>
          <Link
            to="/rooms"
            className={`text-lg ${activeLink === 'rooms' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('rooms')}
          >
            Rooms
          </Link>
          <Link
            to="/camping-details"
            className={`text-lg ${activeLink === 'Campign' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => { handleLinkClick('Campign'); setDropdownVisible(false); }}
          >
            Camping Packages
          </Link>
          <Link
            to="/boat-ride-details"
            className={`text-lg ${activeLink === 'boat-ride' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => { handleLinkClick('boat-ride'); setDropdownVisible(false); }}
          >
            Boat Rides
          </Link>
          <Link
            to="/wellness-package"
            className={`text-lg ${activeLink === 'wellness-package' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => { handleLinkClick('wellness-package'); setDropdownVisible(false); }}
          >
            Wellness Packages
          </Link>
          <Link
            to="/dayout-packages"
            className={`text-lg ${activeLink === 'DayOutPackages' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('DayOutPackages')}
          >
            DayOut Packages
          </Link>
          <Link
            to="/packages"
            className={`text-lg ${activeLink === 'offers' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('offers')}
          >
           Special Packages
          </Link>
          <Link
            to="/payments"
            className={`text-lg ${activeLink === 'payments' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('payments')}
          >
            Payments
          </Link>
          <Link
            to="/contacts"
            className={`text-lg ${activeLink === 'contacts' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('contacts')}
          >
            Contacts
          </Link>
        </div>
      </div>

      {/* Desktop Navigation (hidden on mobile) */}
      <div className="backdrop-blur-sm bg-black/90 py-6 px-4 relative z-10 border-b-[0.2px] hidden md:flex justify-center">
        <div className="flex justify-center space-x-6 text-white/80">
          <Link
            to="/"
            className={`text-[14px] ${activeLink === 'home' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('home')}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`text-[14px] ${activeLink === 'about' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('about')}
          >
            About
          </Link>
          <Link
            to="/rooms"
            className={`text-[14px] ${activeLink === 'rooms' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('rooms')}
          >
            Rooms
          </Link>
          <div className="relative top-[-1.6px]">
            <a
              href="/activities"
              className={`text-[14px] ${activeLink === 'boat-ride' || activeLink === 'wellness-package' || activeLink === 'Campign' ? 'text-Brownish'  : 'text-white/80'}`}
              onClick={handleActivitiesClick}
            >
              Activities
            </a>
            {isDropdownVisible && (
              <div ref={dropdownRef} className="mt-2 absolute left-0 bg-black text-white/80 shadow-lg rounded-md w-[150px]">
                <Link
                  to="/camping-details"
                  className="block px-4 py-2 text-sm"
                  onClick={() => { handleLinkClick('Campign'); setDropdownVisible(false); }}
                >
                  Camping Package
                </Link>
                <Link
                  to="/BBQ-events"
                  className="block px-4 py-2 text-sm"
                  onClick={() => { handleLinkClick('BBQPackage'); setDropdownVisible(false); }}
                >
                 BBQ Parties
                </Link>
                <Link
                  to="/boat-ride-details"
                  className="block px-4 py-2 text-sm"
                  onClick={() => { handleLinkClick('boat-ride'); setDropdownVisible(false); }}
                >
                  Boat Ride Package
                </Link>
                <Link
                  to="/wellness-package"
                  className="block px-4 py-2 text-sm"
                  onClick={() => { handleLinkClick('wellness-package'); setDropdownVisible(false); }}
                >
                  Wellness Package
                </Link>
              </div>
            )}
          </div>
          <Link
            to="/dayout-packages"
            className={`text-[14px] ${activeLink === 'DayOutPackages' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('DayOutPackages')}
          >
            DayOut Packages
          </Link>
          <Link
            to="/packages"
            className={`text-[14px] ${activeLink === 'offers' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('offers')}
          >
            Special Packages
          </Link>
          <Link
            to="/payments"
            className={`text-[14px] ${activeLink === 'payments' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('payments')}
          >
            Payments
          </Link>
          <Link
            to="/contacts"
            className={`text-[14px] ${activeLink === 'contacts' ? 'text-Brownish' : 'hover:text-Brownish'}`}
            onClick={() => handleLinkClick('contacts')}
          >
            Contacts
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
