// src/components/Home.js
import React from 'react';
import WhyChooseUs from '../components/WhyChooseus';
import LakeHouse from '../assets/lake-house.webp'
import CountingBar from '../components/CountingBar';

const Home = () => {
    return (
        <div className='relative font-philosopher'>
            <div className="relative w-full h-screen bg-cover bg-center"
                style={{ backgroundImage: `url(${LakeHouse})` }}>  {/* Fixed this line */}
                {/* Dark overlay */}
                <div className="absolute inset-0 bg-black opacity-70"></div>

                {/* Main content */}
                <div className="absolute inset-0 flex items-center justify-center text-center text-white/90 p-8">
                    <div>
                        {/* Main Caption */}
                        <h1 className="text-3xl md:text-6xl font-bold mb-4">Welcome to <span className='text-Brownish'>RiverSide</span> Hotel</h1>

                        {/* Sub Caption */}
                        <p className="text-md md:text-md text-center">Nestled by the tranquil riverside, our hotel offers a perfect blend of luxury, comfort, and breathtaking views for an unforgettable stay</p>
                        <p className="text-xl md:text-xl">Experience luxury like never before.</p>
                    </div>
                </div>
            </div>

            <CountingBar/>
            <WhyChooseUs />
        </div>
    );
}

export default Home;
