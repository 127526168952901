import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const PaymentModal = ({ isOpen, onClose, packageData }) => {
  // Ensure hooks are called unconditionally
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Only render modal if isOpen is true
  if (!isOpen) return null;

  const { name, price } = packageData;

  const handlePayment = async () => {
    if (!stripe || !elements) return;

    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    
    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    // Here, you would send the token to your server to process the payment.
    // Example:
    // const response = await fetch('/api/payment', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ token, amount: price }),
    // });

    // const paymentResult = await response.json();
    // Handle the result (success or failure) here

    setLoading(false);
    alert('Payment successful!'); // Replace with your success handler
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 font-philosopher">
      <div className="bg-white p-8 rounded-md w-[380px] max-w-full">
        <h2 className="text-2xl font-bold mb-6">Complete Your Payment</h2>

        <p className="text-lg font-semibold mb-4">Package: {name}</p>
        <p className="text-lg text-gray-600 mb-6">Price: LKR {price}</p>

        {error && <div className="text-red-500 mb-4">{error}</div>}

        <div className="mb-4">
          <label className="text-sm font-semibold text-gray-700">Card Details</label>
          <div className="bg-gray-50 border border-gray-300 p-2 rounded-md mt-2">
            <CardElement />
          </div>
        </div>

        <button
          onClick={handlePayment}
          disabled={loading || !stripe}
          className={`w-full py-3 px-4 rounded-md text-white font-semibold ${loading ? 'bg-gray-400' : 'bg-DarkGreen '} mt-4`}
        >
          {loading ? 'Processing...' : 'Pay Now'}
        </button>

        <div className="mt-4 text-center">
          <button
            onClick={onClose}
            className="text-red-600 border-red-500 w-full rounded-md border p-2 text-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
