// CheckoutForm.js (Frontend - Stripe)
import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const CheckoutForm = ({ amount, onClose }) => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    const cardElement = elements.getElement(CardElement);

    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      setError(error.message);
      setProcessing(false);
      return;
    }

    const response = await fetch('/pay-global', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount, token }),
    });

    const data = await response.json();

    if (data.success) {
      alert('Payment successful!');
      onClose();
    } else {
      alert('Payment failed!');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <CardElement />
      </div>
      {error && <div>{error}</div>}
      <button type="submit" disabled={processing || !stripe}>
        {processing ? 'Processing...' : `Pay LKR ${amount}`}
      </button>
    </form>
  );
};

export default CheckoutForm;
