import React from "react";
import AcRoom from '../assets/Ac-Room.webp';
import NonAcRoom from '../assets/Non-Ac.webp';
import { useNavigate } from 'react-router-dom';

const RoomComponent = () => {
    const navigate = useNavigate();

    const handleSeeMore = (roomType) => {
        navigate('/all-rooms', {
            state: { roomType }, // passing the room type (ac or non-ac)
        });
    };

    return (
        
        <div className="flex lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col text-white font-philosopher w-full">
            <div className="relative flex flex-col items-center justify-center bg-cover bg-center w-full lg:h-[500px] md:h-[500px] sm:h-[400px] min-[320px]:h-[300px]" style={{ backgroundImage: `url(${AcRoom})` }}>
                <div className="absolute inset-0 bg-black opacity-55 flex items-center justify-center flex-col">
                    <h2 className="text-white lg:text-3xl md:text-2xl sm:text-xl min-[320px]:text-xl font-semibold">Ac Rooms</h2>
                    <button className="mt-4 px-6 py-2 text-white border border-white bg-transparent hover:bg-white hover:text-black transition duration-300" onClick={() => handleSeeMore('ac')}>See More</button>
                </div>
            </div>
            <div className="relative flex flex-col items-center justify-center bg-cover bg-center w-full lg:h-[500px] md:h-[500px] sm:h-[400px] min-[320px]:h-[300px]" style={{ backgroundImage: `url(${NonAcRoom})` }}>
                <div className="absolute inset-0 bg-black opacity-60 flex items-center justify-center flex-col">
                    <h2 className="text-white lg:text-3xl md:text-2xl sm:text-xl min-[320px]:text-xl font-semibold">Non Ac Rooms</h2>
                    <button className="mt-4 px-6 py-2 text-white border border-white bg-transparent hover:bg-white hover:text-black transition duration-300" onClick={() => handleSeeMore('non-ac')}>See More</button>
                </div>
            </div>
        </div>

    
    );
};

export default RoomComponent;
