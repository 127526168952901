// src/components/Footer.js
import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaGooglePlusG, FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa';
import VisaImage from '../assets/Payements/visacard.png'
import MasterCardImage from '../assets/Payements/mastercard.png'
import SampathImage from '../assets/Payements/SampathBank.png'
import TripAdvisor from '../assets/tripAdvisor.png'

const Footer = () => {
    return (
        <div className=" bg-black/90 pt-9 font-philosopher ">
            <div className="mx-auto w-full px-4 xl:px-0 max-w-[1200px]">
                <div className="flex lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col justify-center gap-x-4 gap-y-4  lg:items-start min-[320px]:items-center">

                    {/* First Column: Logo, Hotel Name, Description, Social Media */}
                    <div className="lg:w-[316px] md:w-full sm:w-[316px] min-[320px]:w-[320px] ">
                        <h1 className="text-white font-extrabold text-xl">
                            <span className="text-Brownish">RiverSide</span>Hotel
                        </h1>
                        <p className="mt-[18px] text-[15px] font-normal text-white/[80%]">
                            Experience the best hospitality at RiverSide, where comfort meets luxury. Come stay<br></br> with us and enjoy unparalleled service.
                        </p>
                        <div className="mt-[18px] flex gap-4">
                            <a href="https://www.facebook.com/riversidehotelcolombosrilanka/" className="hover:scale-110" target="_blank">
                                <FaFacebookF className='text-white' />
                            </a>
                            <a href="https://www.linkedin.com/" className="hover:scale-110" target="_blank">
                                <FaLinkedinIn className='text-white' />
                            </a>
                            <a href="https://twitter.com/" className="hover:scale-110" target="_blank">
                                <FaTwitter className='text-white' />
                            </a>
                            <a href="https://www.youtube.com/" className="hover:scale-110" target="_blank">
                                <FaGooglePlusG className='text-white' />
                            </a>
                        </div>
                    </div>

                    {/* Second Column: Contact Information */}
                    <div className="lg:w-[316px] md:w-full sm:w-[316px] min-[320px]:w-[320px]  ">
                        <p className="text-deutziawhite font-inter text-[18px] font-medium leading-normal text-white"><span className='text-Brownish'>Contact</span> Information</p>

                        <div className="mt-[23px] flex items-center">
                            <div className="flex h-[38px] w-[38px] items-center justify-center rounded-[75%]">
                                <FaPhone className="text-white" />
                            </div>
                            <div className="ml-[18px]">
                                <a href="tel:+94112226752" className="font-Inter text-[14px] font-medium text-white">+94 778 481 494</a>
                                <p className="font-Inter text-[12px] font-medium text-white">Support Number</p>
                            </div>
                        </div>
                        <div className="mt-[23px] flex items-center">
                            <div className="flex h-[38px] w-[38px] items-center justify-center rounded-[75%]">
                                <FaEnvelope className="text-white" />
                            </div>
                            <div className="ml-[18px]">
                                <a href="mailto:info@riversidehotelcolombo.com" className="font-Inter text-[14px] font-medium text-white">riversidecolombo@gmail.com</a>
                                <p className="font-Inter text-[12px] font-medium text-white">Support Email</p>
                            </div>
                        </div>
                        <div className="mt-[23px] flex items-center">
                            <div className="flex h-[38px] w-[38px] items-center justify-center rounded-[75%]">
                                <FaMapMarkerAlt className="text-white" />
                            </div>
                            <div className="ml-[18px]">
                                <p className="font-Inter text-[14px] font-medium text-white">475/A/2 Negombo Road, Dandugama Thotupola, Ja-ela, Sri Lanka.</p>
                                <p className="font-Inter text-[12px] font-medium text-white">Address</p>
                            </div>
                        </div>
                    </div>

                    <div className="lg:w-[316px] md:w-full sm:w-[316px] min-[320px]:w-[320px]  ">
                        <p className="text-deutziawhite font-inter text-[18px] font-medium leading-normal text-white"><span className='text-Brownish'>Our</span> Location</p>
                        <div className="md:w-[316px] flex justify-center items-center mt-[23px] md:mt-0">
                        <div className="w-full max-w-[320px] md:max-w-[400px]">
                            <iframe
                                title="Riverside Hotel Location"
                                width="100%"
                                height="250"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7918.308743525955!2d79.880445!3d7.108102!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2f0f92bc42b45%3A0xa691c507168ddc08!2sRiverside%20Hotel!5e0!3m2!1sen!2slk!4v1735274359994!5m2!1sen!2slk"
                                frameBorder="0"
                                style={{ border: 0 }}
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    </div>

                    {/* Third Column: Pages */}
                    <div className="mt-6 flex w-full flex-col justify-between text-white sm:flex-row md:mt-0 lg:w-[316px] md:w-full sm:w-[316px] min-[320px]:w-[320px]  ">
                        <div className='flex flex-col gap-y-6'>
                            <div>
                                <p className="text-deutziawhite font-inter text-[18px] font-medium leading-normal"><span className='text-Brownish'>Payment</span> Methods</p>

                                <div className='flex flex-row gap-x-3'>
                                    <div className="flex flex-col items-center">
                                        <img src={SampathImage} alt="Visa" className="h-8 mt-2" />
                                        {/* <p className="text-sm text-gray-700">VISA</p> */}
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <img src={VisaImage} alt="Visa" className="h-12" />
                                        {/* <p className="text-sm text-gray-700">VISA</p> */}
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <img src={MasterCardImage} alt="Mastercard" className="h-10 mt-[5px]" />
                                        {/* <p className="text-sm text-gray-700">MASTERCARD</p> */}
                                    </div>
                                </div>
                            </div>

                            <div>
                            <p className="text-deutziawhite font-inter text-[18px] font-medium leading-normal"><span className='text-Brownish'>View</span> Us</p>
                            <a className="flex flex-col items-center" href='https://www.tripadvisor.com/Hotel_Review-g3701972-d8708308-Reviews-Riverside_Hotel-Ja_Ela_Gampaha_Western_Province.html' target='_blank'>
                                        <img src={TripAdvisor} alt="Visa" className="h-10 mt-4 relative right-6" />
                            
                                </a>
                            </div>


                        </div>
                    </div>
                </div>

                {/* All Rights Reserved Section */}
                <div className="text-center text-gray-400 text-sm mt-8 p-4">
                    <p>© 2024 RiverSideHotel. All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
