import React, { useState, useEffect } from 'react';
import roomsData from '../db'; // Importing data from db.js
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../payments/CheckoutForm'; // Component to handle Stripe elements
import LocalPaymentForm from '../payments/LocalPaymentForm'; // Component for local gateways like iPay or PayHere

const stripePromise = loadStripe('your-stripe-publishable-key-here'); // Your Stripe key

const BookingModal = ({ roomType, onClose }) => {
  const [selectedRoomType, setSelectedRoomType] = useState('Single Room');
  const [acType, setAcType] = useState(roomType); // ac or non-ac
  const [hours, setHours] = useState('3');
  const [amount, setAmount] = useState(2500); // Default for Single Room
  const [paymentMethod, setPaymentMethod] = useState('global'); // Local or Global
  const [disabledAc, setDisabledAc] = useState(false);
  const [disabledNonAc, setDisabledNonAc] = useState(false);
  const [loading, setLoading] = useState(false); // To track the loading state of the payment process

  useEffect(() => {
    // Set the default amount and disable AC/Non-AC based on the selected room type
    updateAmountAndDisableOptions();
  }, [selectedRoomType, acType, hours]);

  const updateAmountAndDisableOptions = () => {
    const selectedRoomData =
      acType === 'ac'
        ? roomsData.acRooms.find((room) => room.type === selectedRoomType)
        : roomsData.nonAcRooms.find((room) => room.type === selectedRoomType);

    const selectedAmount =
      hours === '3'
        ? selectedRoomData.threeHours
        : hours === '12'
        ? selectedRoomData.twelveHours
        : selectedRoomData.twentyFourHours;

    setAmount(selectedAmount);

    // Disable non-AC if AC is selected, and vice versa
    if (acType === 'ac') {
      setDisabledAc(true);
      setDisabledNonAc(false);
    } else {
      setDisabledNonAc(true);
      setDisabledAc(false);
    }
  };

  const handleRoomTypeChange = (e) => {
    setSelectedRoomType(e.target.value);
  };

  const handleHoursChange = (e) => {
    setHours(e.target.value);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePayNowClick = () => {
    setLoading(true);
    
    if (paymentMethod === 'global') {
      // Trigger global payment (Stripe or PayPal)
      // This will call the appropriate Stripe or PayPal component
      // You should already have logic inside the CheckoutForm component for Stripe
      setLoading(false);
    } else {
      // Trigger local payment (iPay, PayHere, etc.)
      // This will call the appropriate component or logic for local payment processing
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 m-2">
      <div className="bg-white p-6 rounded-md w-96">
        <h2 className="text-2xl font-bold mb-4 text-DarkGreen">Book Your Room</h2>

        {/* Room Type Selection */}
        <div className="mb-4">
          <label className="block text-sm">Select Room Type</label>
          <select
            value={selectedRoomType}
            onChange={handleRoomTypeChange}
            className="w-full mt-1 p-2 border rounded-md"
          >
            <option value="Single Room">Single Room</option>
            <option value="Double Room">Double Room</option>
            <option value="Family Room">Family Room</option>
          </select>
        </div>

        {/* AC Type Display */}
        <div className="mb-4">
          <label className="block text-sm">AC Type</label>
          <div className="p-2 border rounded-md">
            {acType === 'ac' ? 'AC Room' : 'Non-AC Room'}
          </div>
        </div>

        {/* Hours Selection */}
        <div className="mb-4">
          <label className="block text-sm">Select Hours</label>
          <select
            value={hours}
            onChange={handleHoursChange}
            className="w-full mt-1 p-2 border rounded-md"
          >
            <option value="3">3 Hours</option>
            <option value="12">12 Hours</option>
            <option value="24">24 Hours</option>
          </select>
        </div>

        {/* Payment Method Selection */}
        <div className="mb-4">
          <label className="block text-sm">Payment Method</label>
          <div className="flex gap-x-4">
            <label>
              <input
                type="radio"
                value="global"
                checked={paymentMethod === 'global'}
                onChange={handlePaymentMethodChange}
              />
              Global (Visa/Master/PayPal)
            </label>
            <label>
              <input
                type="radio"
                value="local"
                checked={paymentMethod === 'local'}
                onChange={handlePaymentMethodChange}
              />
              Local (iPay/PayHere)
            </label>
          </div>
        </div>

        {/* Amount */}
        <div className="mb-4">
          <p className="font-bold text-Brownish">Total Amount: LKR {amount}</p>
        </div>

        {/* Render the payment method component based on selection */}
        {paymentMethod === 'global' ? (
          <Elements stripe={stripePromise}>
            <CheckoutForm amount={amount} onClose={onClose} />
          </Elements>
        ) : (
          <LocalPaymentForm amount={amount} onClose={onClose} />
        )}

        {/* Pay Now Button */}
        <button
          onClick={handlePayNowClick}
          className="w-full bg-DarkGreen text-white p-2 rounded-md mt-3"
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Pay Now'}
        </button>

        <button
          onClick={onClose}
          className="mt-4 w-full text-center text-red-500 bg-transparent border border-red-500 rounded-md p-2"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default BookingModal;
