import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Local storage
import activeLinkReducer from './reducer'; // Import the active link reducer
import { combineReducers } from 'redux';

// Persist config for storing the active link
const persistConfig = {
  key: 'root',
  storage,
};

// Combine reducers (you can add more reducers here as needed)
const rootReducer = combineReducers({
  activeLink: activeLinkReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store
export const store = configureStore({
  reducer: persistedReducer,
});

// Create the persist store
export const persistor = persistStore(store);
