// src/components/AboutUs.js
import React from 'react';
import ImageOne from '../assets/gallery/BBQ.webp'
import ImageTwo from '../assets/gallery/roomOne.webp'
import ImageThree from '../assets/gallery/roomTwo.webp'
import ImageFour from '../assets/gallery/OutDoorView.webp'
import ImageFive from '../assets/gallery/OutDoorViews.webp'
import ImageSix from '../assets/gallery/drinks.webp'
import ImageSeven from '../assets/gallery/crane.webp'
import ImageEight from '../assets/gallery/poolView.webp'
import ImageNine from '../assets/gallery/BoatSafari.webp'
import ImageTen from '../assets/gallery/biriyani.webp'
import ImageElleven from '../assets/gallery/poolViewTwo.webp'

const AboutUs = () => {
  return (
    <div className='relative  font-philosopher bg-slate-50'>
      <div
        className=""
        
      >
        <div className="flex flex-col gap-y-5 lg:p-10 md:p-8 sm:p-6 min-[320px]:p-5">
        <h2 className="text-3xl font-bold text-Brownish">View Our Gallery </h2>

          {/* Left side: Image Gallery with New Layout */}
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            <div className="grid gap-4">
              <div className=''>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src={ImageNine}
                  alt="gallery-photo"
                />
              </div>
              <div className=''>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center "
                  src={ImageTen}
                  alt="gallery-photo"
                />
              </div>
              <div className=''>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src={ImageElleven}
                  alt="gallery-photo"
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div className=''>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src={ImageOne}
                  alt="gallery-photo"
                />
              </div>
              <div className=''>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src={ImageTwo}
                  alt="gallery-photo"
                />
              </div>
              <div className=''>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center "
                  src={ImageThree}
                  alt="gallery-photo"
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div >
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src={ImageFour}
                  alt="gallery-photo"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center "
                  src={ImageFive}
                  alt="gallery-photo"
                />
              </div>
              <div className=''>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src={ImageSix}
                  alt="gallery-photo"
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div className=''>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src={ImageSeven}
                  alt="gallery-photo"
                />
              </div>
              <div className=''>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src={ImageEight}
                  alt="gallery-photo"
                />
              </div>
            </div>
          </div>


          {/* Right side: About Content */}
          <div className=" flex lg:flex-row md:flex-col sm:flex-col min-[320px]:flex-col gap-x-4 gap-y-4 ">
            

            <div className='lg:w-1/3 bg-Brownish/30 p-4 rounded-md '>
            <h3 className="text-3xl font-semibold text-Brownish">About Us</h3>
            <p className="text-black mt-4">
            Riverside Hotel is located by the waterfront, offering a variety of services including spa treatments, Ayurvedic treatments, and an on-site buffet restaurant. Guests can enjoy free Wi-Fi access throughout the hotel. The rooms are simply furnished with tiled flooring and clean linen sheets, providing a comfortable stay. Each room comes with an en-suite bathroom, featuring hot/cold shower facilities, a flat-screen TV, and a minibar with a fridge.

            </p>
            </div>

            <div className='lg:w-1/3 bg-DarkGreen/30 p-4 rounded-md'>
            <h3 className="text-3xl font-semibold text-DarkGreen">Our Story</h3>
            <p className="text-black mt-4">
            At Riverside Hotel, we offer a relaxing experience with spa treatments, authentic Sri Lankan meals, and room service, including barbecued dishes. Start your day with breakfast in your room upon request. Located just 4 km from Bandaranaike International Airport and 15 minutes from Colombo, we provide an affordable and comfortable stay.
            </p>
            </div>

            <div className='lg:w-1/3 bg-Brownish/30 p-4 rounded-md'>
            <h3 className="text-3xl font-semibold text-Brownish">Our Vision</h3>
            <p className="text-black mt-4">
              Our vision is to create a home away from home for all our guests, providing them with the highest standard of service
              and comfort. We are constantly innovating to meet the evolving needs of our customers while maintaining a warm, welcoming atmosphere.
            </p>
            </div>

            
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
